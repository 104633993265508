import * as React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { calculateImageColumnWidths } from '/src/functions/columns/calculateColumnWidths'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const ImageList = ({
    backgroundColour,
    blockSettings,
    numberOfColumns,
    titleArea,
    imageList,
    addSmallprint,
    smallprint,
    className,
    utm
}) => {
    const paddingSize = blockSettings?.paddingSize

    const classes = ['c-image-list-block', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    const columnWidth = calculateImageColumnWidths(numberOfColumns)

    return (
        <Section variant={backgroundColour} size={paddingSize} titleArea={titleArea} className={concatenatedClasses}>
            {imageList && (
                <div className="row c-image-list-block__content-row">
                    {imageList.map((node, index) => {
                        if (node.link) {
                            const ctaHref = `${node.link.url}${utm}`

                            return (
                                <div key={index} className={`${columnWidth} c-image-list-block__item`}>
                                    <Link className="c-image-list-block__item-inner" to={ctaHref}>
                                        <Image data={node.image} className={'c-image-list-block__item-image'} />
                                    </Link>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index} className={`${columnWidth} c-image-list-block__item`}>
                                    <div className="c-image-list-block__item-inner">
                                        <Image data={node.image} className={'c-image-list-block__item-image'} />
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            )}

            {addSmallprint && smallprint && (
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <ContentDisclaimer content={smallprint} />
                    </div>
                </div>
            )}
        </Section>
    )
}

ImageList.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    numberOfColumns: PropTypes.oneOf(['12', '6', '4', '3', '2']),
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    imageList: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object,
            link: PropTypes.shape({
                url: PropTypes.string.isRequired,
                title: PropTypes.string,
                target: PropTypes.string
            })
        })
    ).isRequired,
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ImageList.defaultProps = {
    backgroundColour: 'light',
    numberOfColumns: '6',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    imageList: [{}, {}, {}, {}, {}],
    addSmallprint: false,
    smallprint: '<p>I am some smallprint</p>',
    utm: ''
}

export default ImageList

export const query = graphql`
    fragment ImageListForPageBuilder on WpPage_Acfpagebuilder_Layouts_ImageList {
        fieldGroupName
        blockSettings {
            paddingSize
        }
        backgroundColour
        numberOfColumns
        addTitleArea
        title
        description
        imageList {
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 280)
                    }
                }
                altText
                title
            }
            link {
                target
                title
                url
            }
        }
        addSmallprint
        smallprint
    }
`
